<template>
  <div>
    <b-modal
      @hidden="clearForm"
      v-model="modalShow"
      title="Stock Management"
      centered
      footer-class="content-between"
    >
      <b-tabs fill v-model="form.edit_type">
        <b-tab title="Received">
          <div class="container-stock">
            <InputSelect
              placeholder="Stock"
              title="Stock"
              v-model="form.stock_type_id"
              :disabled="stockDisabled"
              :isValidate="$v.form.stock_type_id.$error"
              :v="$v.form.stock_type_id"
              valueField="id"
              @onDataChange="handleInventoryReceive($event)"
              textField="name"
              :options="listStock"
            >
              <template v-slot:option-first>
                <b-form-select-option :value="0" disabled
                  >-- Select Stock --</b-form-select-option
                >
              </template>
            </InputSelect>

            <!-- <div class="current-stock">Current Stock : {{ form.stock }}</div> -->
            <div v-if="form.is_serial">
              <b-row>
                <b-col cols="12">
                  <label class="main-label">Serial</label>
                </b-col>
              </b-row>
              <div class="px-3 maxheight-290px">
                <b-row
                  v-for="(item, index) in form.serial"
                  :key="`serial-received-${index}`"
                >
                  <b-col cols="1" class=""
                    ><div class="d-flex justify-content-center mt-2">
                      #{{ index + 1 }}
                    </div>
                  </b-col>
                  <b-col cols="10" class="p-0">
                    <InputText
                      textFloat=""
                      placeholder="Serial"
                      :isValidate="$v.form.serial.$each[index].text.$error"
                      :v="$v.form.serial.$each[index].text"
                      v-model="item.text"
                    />
                  </b-col>
                  <b-col cols="1" class="">
                    <div class="d-flex justify-content-center">
                      <b-button
                        variant="icon"
                        class="px-0"
                        :class="{
                          'text-gray-1': index == 0,
                          'text-error': index != 0,
                        }"
                        @click="deleteSerial(index)"
                      >
                        <b-icon icon="trash-fill"></b-icon>
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </div>
              <b-row>
                <b-col cols="12">
                  <div
                    class="main-label text-link main-color"
                    @click="addSerial()"
                  >
                    Add New Serial
                  </div>
                </b-col>
              </b-row>
            </div>
            <InputText
              v-else
              textFloat="Amount"
              placeholder="Add Amount"
              type="number"
              name="Amount"
              v-model="form.amount"
              :disabled="form.stock_type_id === 0"
              @input="handleAmoutInput"
              :isValidate="$v.form.amount.$error"
              :v="$v.form.amount"
            />

            <InputText
              textFloat=""
              customLabel
              placeholder="Available"
              type="number"
              name="Available"
              @input="handleAmoutInput"
              disabled
              v-model="form.available"
            >
              <template v-slot:textFloat>
                <div class="content-between">
                  <div class="main-label">Available</div>
                  <div class="current-stock">
                    Current Stock : {{ form.stock }}
                  </div>
                </div>
              </template>
            </InputText>
            <InputTextArea
              :rows="4"
              textFloat="Additional Notes"
              placeholder="Additional Notes"
              id="note"
              v-model="form.note"
            />
          </div>
        </b-tab>
        <b-tab title="Damaged">
          <div class="container-stock">
            <InputSelect
              placeholder="Stock"
              title="Stock"
              v-model="form.stock_type_id"
              :disabled="stockDisabled"
              :isValidate="$v.form.stock_type_id.$error"
              :v="$v.form.stock_type_id"
              valueField="id"
              @onDataChange="handleInventoryReceive($event)"
              textField="name"
              :options="listStock"
            >
              <template v-slot:option-first>
                <b-form-select-option :value="0" disabled
                  >-- Select Stock --</b-form-select-option
                >
              </template>
            </InputSelect>
            <!-- <div class="current-stock">Current Stock : {{ form.stock }}</div> -->
            <div v-if="form.is_serial">
              <b-row>
                <b-col cols="12">
                  <label class="main-label">Serial</label>
                </b-col>
              </b-row>
              <div class="px-3 maxheight-290px">
                <b-row class="mb-3">
                  <b-col cols="12">
                    <b-input-group class="justify-content-end">
                      <b-form-input
                        textFloat=""
                        :disabled="!form.stock_type_id"
                        placeholder="Search Serial"
                        type="text"
                        v-model="searchSerial"
                        @input="handlesearchSerial"
                      >
                      </b-form-input>
                      <b-input-group-append is-text class="mr-2">
                        <b-iconstack
                          font-scale="2"
                          type="submit"
                          @click="handlesearchSerial"
                          :disabled="tab === 3 ? true : false"
                        >
                          <b-icon
                            stacked
                            icon="search"
                            scale="0.5"
                            variant="grey"
                          ></b-icon>
                        </b-iconstack>
                      </b-input-group-append> </b-input-group
                  ></b-col>
                </b-row>
                <b-row
                  v-for="(item, index) in filteredSerial"
                  :key="`serial-damage-${index}`"
                >
                  <b-col cols="1" class=""
                    ><div class="d-flex justify-content-center mt-2">
                      #{{ index + 1 }}
                    </div>
                  </b-col>
                  <b-col cols="10" class="p-0">
                    <InputText
                      textFloat=""
                      :disabled="true"
                      placeholder="Serial"
                      type="text"
                      v-model="item.serial"
                    />
                  </b-col>
                  <b-col cols="1" class="">
                    <b-form-checkbox
                      class="pl-3 mt-2"
                      :id="item.serial"
                      name="damage"
                      :disabled="!form.stock_type_id"
                      :value="item.serial"
                      :checked="form.serial"
                      :unchecked-value="false"
                      @change="inputSerial(item.serial, 0)"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>
                <div v-if="$v.form.serial.$error" class="text-error">
                  Please Select Serial
                </div>
              </div>
            </div>
            <InputText
              v-else
              textFloat="Amount"
              placeholder="Add Amount"
              type="number"
              name="Amount"
              v-model="form.amount"
              :disabled="form.stock_type_id === 0"
              @input="handleAmoutInput"
              :isValidate="$v.form.amount.$error"
              :v="$v.form.amount"
            />
            <InputText
              textFloat=""
              customLabel
              placeholder="Available"
              type="number"
              name="Available"
              disabled
              v-model="form.available"
            >
              <template v-slot:textFloat>
                <div class="content-between">
                  <div class="main-label">Available</div>
                  <div class="current-stock">
                    Current Stock : {{ form.stock }}
                  </div>
                </div>
              </template>
            </InputText>
            <InputTextArea
              :rows="4"
              textFloat="Additional Notes"
              placeholder="Additional Notes"
              id="note"
              v-model="form.note"
            /></div
        ></b-tab>
        <b-tab title="Adjust" v-if="!form.is_serial">
          <div class="container-stock">
            <InputSelect
              placeholder="Stock"
              title="Stock"
              v-model="form.stock_type_id"
              :disabled="stockDisabled"
              :isValidate="$v.form.stock_type_id.$error"
              :v="$v.form.stock_type_id"
              valueField="id"
              @onDataChange="handleInventoryReceive($event)"
              textField="name"
              :options="listStock"
            >
              <template v-slot:option-first>
                <b-form-select-option :value="0" disabled
                  >-- Select Stock --</b-form-select-option
                >
              </template>
            </InputSelect>

            <!-- <InputText

              placeholder="Add Amount"
              type="number"
              name="Amount"
              v-model="form.amount"
              :isValidate="$v.form.amount.$error"
              :v="$v.form.amount"
            /> -->
            <InputText
              textFloat=""
              customLabel
              placeholder="Add Amount"
              type="number"
              name="Amount"
              v-model="form.amount"
              :isValidate="$v.form.amount.$error"
              :disabled="form.stock_type_id === 0"
              :v="$v.form.amount"
            >
              <template v-slot:textFloat>
                <div class="content-between">
                  <div class="main-label">Amount</div>
                  <div class="current-stock">
                    Current Stock : {{ form.stock }}
                  </div>
                </div>
              </template>
            </InputText>
            <!-- <InputText
              textFloat=""
              customLabel
              placeholder="Available"
              type="number"
              name="Available"
              disabled
              v-model="form.available"
            >
              <template v-slot:textFloat>
                <div class="content-between">
                  <div class="main-label">Available</div>
                  <div class="current-stock">
                    Current Stock : {{ form.stock }}
                  </div>
                </div>
              </template>
            </InputText> -->
            <InputTextArea
              :rows="4"
              textFloat="Additional Notes"
              placeholder="Additional Notes"
              id="note"
              v-model="form.note"
            /></div
        ></b-tab>
        <b-tab title="Move">
          <div class="container-stock">
            <InputSelect
              placeholder="Stock"
              title="Form Stock"
              v-model="form.inventory_from"
              :isValidate="$v.form.inventory_from.$error"
              :v="$v.form.inventory_from"
              @onDataChange="handleInventoryFrom($event, 0)"
              valueField="id"
              :disabled="stockDisabled"
              textField="name"
              :options="listStock"
            >
              <template v-slot:option-first>
                <b-form-select-option :value="0" disabled
                  >-- Select Stock --</b-form-select-option
                >
              </template>
            </InputSelect>
            <div v-if="form.is_serial">
              <b-row>
                <b-col cols="12">
                  <label class="main-label">Serial</label>
                </b-col>
              </b-row>
              <div class="px-3 maxheight-290px">
                <b-row class="mb-3">
                  <b-col cols="12">
                    <b-input-group class="justify-content-end">
                      <b-form-input
                        textFloat=""
                        :disabled="!form.inventory_from"
                        placeholder="Search Serial"
                        type="text"
                        v-model="searchSerial"
                        @input="handlesearchSerial"
                      >
                      </b-form-input>
                      <b-input-group-append is-text class="mr-2">
                        <b-iconstack
                          font-scale="2"
                          type="submit"
                          @click="handlesearchSerial"
                          :disabled="tab === 3 ? true : false"
                        >
                          <b-icon
                            stacked
                            icon="search"
                            scale="0.5"
                            variant="grey"
                          ></b-icon>
                        </b-iconstack>
                      </b-input-group-append> </b-input-group
                  ></b-col>
                </b-row>

                <b-row
                  class=""
                  v-for="(item, index) in filteredSerial"
                  :key="`serial-move-${index}`"
                >
                  <b-col cols="1" class=""
                    ><div class="d-flex justify-content-center mt-2">
                      #{{ index + 1 }}
                    </div>
                  </b-col>
                  <b-col cols="10" class="p-0">
                    <InputText
                      textFloat=""
                      :disabled="true"
                      placeholder="Serial"
                      type="text"
                      v-model="item.serial"
                    />
                  </b-col>
                  <b-col cols="1" class="">
                    <b-form-checkbox
                      class="pl-3 mt-2"
                      name=""
                      :disabled="!form.inventory_from"
                      :value="item.serial"
                      :checked="form.serial"
                      :unchecked-value="false"
                      @change="inputSerial(item.serial, 1)"
                    >
                    </b-form-checkbox>
                  </b-col>
                </b-row>

                <div v-if="$v.form.serial.$error" class="text-error">
                  Please Select Serial
                </div>
              </div>
            </div>
            <InputText
              v-else
              textFloat="Amount"
              placeholder="Add Amount"
              type="number"
              name="Amount"
              v-model="form.amount"
              @input="handleAmoutInput"
              :isValidate="$v.form.amount.$error"
              :disabled="form.inventory_from === 0"
              :v="$v.form.amount"
            />

            <InputText
              textFloat=""
              customLabel
              placeholder="Available"
              type="number"
              name="Available"
              disabled
              v-model="form.available"
            >
              <template v-slot:textFloat>
                <div class="content-between">
                  <div class="main-label">Available</div>
                  <div class="current-stock">
                    Current Stock : {{ form.stock_from }}
                  </div>
                </div>
              </template>
            </InputText>

            <InputSelect
              placeholder="Stock"
              title="To Stock"
              v-model="form.stock_type_id"
              :isValidate="$v.form.stock_type_id.$error"
              :v="$v.form.stock_type_id"
              @onDataChange="handleInventoryFrom($event, 1)"
              valueField="id"
              :disabled="!form.inventory_from"
              textField="name"
              :options="listStockTo"
            >
              <template v-slot:option-first>
                <b-form-select-option :value="0" disabled
                  >-- Select Stock --</b-form-select-option
                >
              </template>
            </InputSelect>

            <InputText
              textFloat=""
              customLabel
              placeholder="Available"
              type="number"
              name="Available"
              disabled
              v-model="form.available_to"
            >
              <template v-slot:textFloat>
                <div class="content-between">
                  <div class="main-label">Available</div>
                  <div class="current-stock">
                    Current Stock : {{ form.stock }}
                  </div>
                </div>
              </template>
            </InputText>
            <InputTextArea
              :rows="4"
              textFloat="Additional Notes"
              placeholder="Additional Notes"
              id="note"
              v-model="form.note"
            /></div
        ></b-tab>
      </b-tabs>
      <template #modal-footer>
        <b-row>
          <b-col class="text-left">
            <b-button @click.prevent="clearForm" class="border-btn"
              >Cancel</b-button
            >
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button class="submit-btn" @click.prevent="handleOk()"
              >Confirm
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { requiredIf, minValue } from "vuelidate/lib/validators";
const minInventoryForm = (value, form) => {
  return !(
    ((form.is_serial && form.edit_type == 2) || form.edit_type == 3) &&
    value == 0
  );
};

const minAmount = (value, form) => {
  return form.is_serial || !(form.is_serial && !value);
};
export default {
  props: {
    stock_id: { required: false, default: 0 },
  },
  data() {
    return {
      modalShow: false,
      form: {
        inventory_id: 0,
        branch_id: Number(this.$route.params.id),
        product_id: 0,
        stock: 0,
        inventory_from: 0,
        stock_from: 0,
        amount: "",
        edit_type: 0,
        note: "",
        available: 0,
        is_serial: 0,
        serial: [],
        stock_type_id: this.stock_id,
        available_to: 0,
      },
      // move_from: {
      //   stock_type_id: null,
      //   inventory_from: null,
      //   branch_id: Number(this.$route.params.id),
      //   stock_form: 0,
      //   product_id: 0,
      //   inventory_id: 0,
      //   edit_type: 4,
      // },
      tabsIndex: 1,
      isHaveItems: false,
      listStock: [],
      listStockTo: [],
      listActiveSerial: [],
      listSerial: [],
      filteredSerial: [],
      searchSerial: "",
      bracnchStock: "",
    };
  },
  validations: {
    form: {
      amount: {
        required: requiredIf(function (item) {
          return !item.is_serial;
        }),
        minAmount() {
          return minAmount(this.form.amount, this.form);
        },
      },
      stock_type_id: {
        minValue: minValue(1),
      },
      inventory_from: {
        minInventoryForm() {
          return minInventoryForm(this.form.inventory_from, this.form);
        },
      },
      serial: {
        required: requiredIf(function () {
          return (
            this.form.is_serial &&
            (this.form.edit_type == 1 || this.form.edit_type == 2)
          );
        }),
        $each: {
          text: {
            required: requiredIf(function () {
              return this.form.edit_type == 0;
            }),
          },
        },
      },
    },
  },
  computed: {
    stockDisabled() {
      return this.stock_id != 0;
    },
  },
  watch: {
    "form.edit_type"(val) {
      this.$v.form.$reset();
      this.listSerial = [];
      this.form.stock_from = 0;

      this.filteredSerial = this.form.is_serial ? this.listSerial : [];
      this.searchSerial = "";
      this.form.amount = "";
      this.form.stock = 0;
      this.form.stock_type_id = val == 3 ? 0 : this.stock_id;
      this.form.inventory_from = val == 3 ? this.stock_id : 0;

      if (this.bracnchStock) {
        if (!this.form.is_serial || val !== 0)
          this.form.available = this.bracnchStock ? this.form.available : 0;
        else this.form.available = this.form.available + 1;
      }

      this.form.available_to = 0;
      this.form.is_serial && this.form.edit_type == 0
        ? (this.form.serial = [{ text: "" }])
        : (this.form.serial = []);

      //แก้ส่วนของ stock inventory (ชั่วคราวกันพี่เจมส์เจอ)
      if (this.bracnchStock) {
        if (val == 0) {
          this.handleInventoryReceive(this.bracnchStock);
        } else if (
          (val == 2 && this.form.is_serial) ||
          (val == 3 && !this.form.is_serial)
        ) {
          this.form.inventory_from = this.bracnchStock;
          this.form.stock_type_id = 0;
          this.handleInventoryFrom(this.bracnchStock, 0);
        } else {
          this.form.inventory_from = 0;
          this.form.stock_type_id = this.bracnchStock;
          this.handleInventoryReceive(this.bracnchStock);
        }
      }
    },
  },
  methods: {
    handleAmoutInput(value, $event) {
      if (this.form.edit_type == 1 || this.form.edit_type == 3) {
        let stock =
          this.form.edit_type == 3 ? this.form.stock_from : this.form.stock;

        if (stock < this.form.amount) {
          return this.$nextTick(() => {
            $event.preventDefault();

            this.form.amount = Number(stock);
            this.form.available = 0;
            return ($event.target.value = Number(stock));
          });
        }
      }
      if (this.form.edit_type == 0) {
        console.log(this.form.edit_type, this.form.stock);
        let amount = this.form.amount ? this.form.amount : 0;
        this.form.available = Number(amount) + Number(this.form.stock);
      } else if (
        this.form.edit_type == 3 ||
        (this.form.is_serial && this.form.edit_type == 2)
      ) {
        let amount = this.form.amount ? this.form.amount : 0;
        this.form.available = Number(this.form.stock_from) - Number(amount);
        this.form.available_to = this.form.stock_type_id
          ? Number(this.form.stock) + Number(amount)
          : 0;
      } else {
        let amount = this.form.amount ? this.form.amount : 0;
        this.form.available = Number(this.form.stock) - Number(amount);
      }
    },
    async handleOk() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        console.log(this.$v.form);
        return;
      }

      this.form.amount = Number(this.form.amount);
      let edit_type = this.form.edit_type + 1;
      if ((this.form.is_serial && edit_type == 3) || edit_type == 4) {
        edit_type = 9;
      }
      //Check Serial
      if (this.form.is_serial && edit_type == 1) {
        const serialList = this.form.serial.map((item) => item.text);
        const hasDuplicates = serialList.some(
          (text, index) => serialList.indexOf(text) !== index
        );
        if (hasDuplicates) {
          this.errorAlert("Duplicate serial number");

          return;
        } else {
          let validate = await this.validateSerial();
          if (!validate) {
            return;
          }
        }
      }

      this.$bus.$emit("showLoading");

      const response = await this.axios.post(`/Inventory/UpdateInventory`, {
        ...this.form,
        edit_type: edit_type,
        serial:
          edit_type == 1
            ? this.form.serial.map((item) => item.text)
            : this.form.serial,
      });

      if (response.data.result) {
        this.modalShow = false;
        this.successAlert();
      } else this.errorAlert(response.data.message);

      this.$emit("getData");
      this.$bus.$emit("hideLoading");
    },
    async validateSerial() {
      const res = await this.axios.post(
        `/Inventory/UpdateInventory/validate/serial`,
        { ...this.form, serial: this.form.serial.map((item) => item.text) }
      );
      if (!res.data.detail.result) {
        this.errorAlert(res.data.detail.message);
        return false;
      }
      return true;
    },
    async show(items) {
      let product_id =
        items.product_id == 0
          ? Number(this.$route.params.id)
          : items.product_id;
      const res = await this.axios.get(
        `/Inventory/GetActiveStockType/${items.branch_id}/${product_id}`
      );
      if (res.data.result) {
        this.listStock = res.data.detail.map((x) => ({
          ...x,
          name: x.name + " (" + x.stock + ")",
          disabled: false,
        }));
        this.listStockTo = this.listStock;
      }
      this.isHaveItems = true;

      this.form.edit_type = 0;

      this.form.serial = items.is_serial ? [{ text: "" }] : [];
      this.form.product_id = product_id;
      this.form.note = items.note || "";
      this.form.branch_id = items.branch_id || Number(this.$route.params.id);
      this.form.is_serial = items.is_serial;
      if (items.stock_type_id && (this.$route.path.includes("/stock")) || (this.$route.path.includes("/product"))) {
        this.form.stock_type_id = items.stock_type_id;
        this.bracnchStock = items.stock_type_id;
        await this.handleInventoryReceive(items.stock_type_id);
        this.form.inventory_from = items.inventory_from;
      }
      this.modalShow = true;
    },
    clearForm() {
      this.$v.form.$reset();
      this.$v.form.serial.$reset();
      this.form = {
        inventory_id: 0,
        branch_id: Number(this.$route.params.id),
        product_id: 0,
        stock: 0,
        inventory_from: 0,
        stock_from: 0,
        amount: "",
        edit_type: 0,
        note: "",
        available: 0,
        is_serial: 0,
        serial: [],
        stock_type_id: 0,
        available_to: 0,
      };
      this.listSerial = [];
      this.modalShow = false;
    },
    addSerial() {
      this.form.serial.push({ text: "" });
      if (!this.form.stock_type_id && !this.form.inventory_from) {
        return;
      } else {
        this.form.available += 1;
      }
    },
    deleteSerial(index) {
      if (index == 0) {
        return;
      } else {
        this.form.serial.splice(index, 1);
        this.form.available -= 1;
      }
    },
    async handleInventoryReceive(input) {
      this.form.inventory_id = this.listStock.find(
        (x) => x.id == input
      ).inventory_id;
      this.filteredSerial = this.form.is_serial ? this.listSerial : [];
      this.searchSerial = "";
      this.form.available =
        this.form.is_serial && this.form.edit_type == 0
          ? this.listStock.find((x) => x.id == input).stock +
            this.form.serial.length
          : this.listStock.find((x) => x.id == input).stock;
      this.form.stock = this.listStock.find((x) => x.id == input).stock;
      await this.getSerialFromStock();
    },
    async handleInventoryFrom(input, isToStock) {
      if (!isToStock) {
        this.listStockTo = this.listStock.map((x) => ({
          ...x,
          disabled: false,
        }));
        //Clear stock to
        this.form.stock_type_id = 0;
        this.form.available_to = 0;
        this.form.stock = 0;

        //Clear stock to
        this.form.amount = "";
        this.form.stock_from = this.listStock.find((x) => x.id == input).stock;
        this.form.available = this.listStock.find((x) => x.id == input).stock;

        const stockToItem = this.listStockTo.find((x) => x.id == input);
        if (stockToItem) {
          stockToItem.disabled = true;
        }
        await this.getSerialFromStock();
      } else {
        let stockToItem = this.listStockTo.find((x) => x.id == input);
        this.form.available_to = this.form.is_serial
          ? stockToItem.stock + this.form.serial.length
          : stockToItem.stock + this.form.amount;
        this.form.stock = this.listStock.find((x) => x.id == input).stock;

        this.form.inventory_id = this.listStockTo.find(
          (x) => x.id == input
        ).inventory_id;
      }
    },
    async getSerialFromStock() {
      if (this.form.is_serial) {
        const responseSerial = await this.axios(
          `/Inventory/GetInventorySerial/${this.form.branch_id}/${
            this.form.product_id
          }/${this.form.stock_type_id || this.form.inventory_from}`
        );
        this.listSerial = responseSerial.data.detail;
        this.filteredSerial = responseSerial.data.detail;
      }
    },

    inputSerial(serial, isMove) {
      if (this.form.serial.includes(serial)) {
        this.form.serial = this.form.serial.filter((item) => item !== serial);
        this.form.available += 1;
        if (isMove && this.form.stock_type_id) {
          this.form.available_to -= 1;
        }
      } else {
        this.form.serial.push(serial);

        this.form.available -= 1;
        if (isMove && this.form.stock_type_id) {
          this.form.available_to += 1;
        }
      }
    },
    handlesearchSerial(val) {
      this.filteredSerial = this.listSerial.filter((x) =>
        x.serial.toLowerCase().includes(val.toLowerCase())
      );
    },
  },
};
</script>

<style scoped>
::v-deep .nav.nav-tabs.nav-fill {
  border: 10px solid #c5c5c5;
}
::v-deep .modal-body {
  padding: 0;
}
::v-deep .nav-tabs .nav-link.active,
::v-deep .nav-tabs .nav-item.show .nav-link {
  background-color: var(--primary-color);
  border-bottom: none;
  color: #fff !important;
}
::v-deep .nav-tabs .nav-item .nav-item:last-child {
  padding-right: 0px;
}

.container-stock {
  position: relative;
  padding: 1.5rem;
}
.current-stock {
  color: rgb(146, 146, 146);
  /* position: absolute; */
  /* top: calc(0px+ 1.5rem); */
  font-size: 12px;
  /* top: calc(0px+ 1.5rem); */
  /* right: calc(0px + 1.5rem); */
}

.maxheight-290px {
  max-height: 290px !important;
  overflow: auto;
}

input.search-bar {
  box-shadow: inset 0 1px 2px hsl(0deg 0% 7% / 10%);
}
input#header-search-bar::placeholder {
  text-transform: capitalize;
}
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

::v-deep .form-control:disabled,
.form-control[readonly] {
  background-color: whitesmoke;
}

::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
</style>
